import React, { useState } from 'react'
import useDebounce from '../../lib/useDebounce'
import useUser from '../../lib/useUser'
import useUsers from '../../lib/useUsers'
import BasicErrorMessage from '../common/BasicErrorMessage'
import EmptyView from '../common/EmptyView'
import TextInput from '../common/TextInput'
import PaginatedWithButton from '../common/pagination/PaginatedWithButton'
import SmallUserItem from './SmallUserItem'
import UserCell from './UserCell'

export default function UserSearch({
  onSelectUser,
  selectable,
  selectedUsers,
}) {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  return (
    <div key="user-search">
      <TextInput
        placeholder="Search for people"
        className="mb-10 px-2 md:px-0"
        value={search}
        onChangeText={setSearch}
        autoFocus
      />
      <UserResults
        search={debouncedSearch}
        selectedUsers={selectedUsers}
        onSelectUser={onSelectUser}
        selectable={selectable}
      />
    </div>
  )
}

export function UserResults({
  search,
  selectable,
  selectedUsers,
  onSelectUser,
}) {
  const { users, error, ...attributes } = useUsers({ search })
  const { user } = useUser()
  const otherUsers = users?.filter((u) => u.id !== user.id)

  if (error) {
    return <BasicErrorMessage error="Unable to fetch users" />
  }

  if (otherUsers == null || otherUsers.length === 0) {
    return <EmptyView title="No users found" />
  }

  return (
    <div className="flex flex-col">
      {selectedUsers != null && (
        <SelectedUsersRow
          selectedUsers={selectedUsers}
          onSelectUser={onSelectUser}
          currentUser={user}
        />
      )}
      <div
        className="grid grid-cols-2 gap-6 overflow-auto"
        style={{ maxHeight: '50vh' }}
      >
        <PaginatedWithButton
          {...attributes}
          buttonClassName="w-2/3 h-2/3 md:h-1/2"
        >
          {otherUsers.map((user, i) => (
            <UserCell
              key={i}
              slug={user.slug}
              name={user.name}
              avatar={user.avatar}
              onClick={() => onSelectUser(user)}
              selected={selectedUsers?.find((u) => u.id === user.id) != null}
              selectable={selectable}
            />
          ))}
        </PaginatedWithButton>
      </div>
    </div>
  )
}

export function SelectedUsersRow({ selectedUsers, onSelectUser, currentUser }) {
  return (
    <div className="flex flex-row flex-wrap border-b border-divider pb-4 mb-5">
      <div key={currentUser.id} className="mr-4 mb-4">
        <SmallUserItem user={currentUser} />
      </div>
      {selectedUsers.map((user) => (
        <div key={user.id} className="mr-4 mb-4">
          <SmallUserItem user={user} onClick={() => onSelectUser(user)} />
        </div>
      ))}
    </div>
  )
}
