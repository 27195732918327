import classnames from 'classnames'
import CLAvatar from '../common/CLAvatar'
import Icon from '../common/Icon'
import { Popout, PopoutContent, PopoutTrigger } from '../common/popout/Popout'

export default function UserCell({
  name,
  avatar,
  slug,
  ActionsComponents,
  className,
  onClick,
  selected,
  selectable,
  zIndex = 100,
}) {
  const classes = classnames(
    'group flex flex-row items-center justify-between p-2 rounded-lg',
    className,
    {
      'hover:cursor': onClick != null,
      'bg-topic-green bg-opacity-20': selected,
      'hover:bg-topic-green hover:bg-opacity-20': selectable,
      'cursor-pointer': onClick != null || selectable,
    }
  )
  const selectableAccessoryClasses = classnames(
    'bg-topic-green bg-opacity-50 rounded flex items-center justify-center w-5 h-5',
    {
      'opacity-0 group-hover:opacity-100': !selected,
    }
  )
  return (
    <button onClick={onClick} className={classes}>
      <div className="flex flex-row items-center focus:outline-none">
        <div className="flex-shrink-0 mt-2">
          <CLAvatar
            key={`${name}-user-cell-avatar`}
            name={name}
            avatar={avatar}
            width={24}
            height={24}
            className="bg-primary rounded-full object-cover"
          />
        </div>
        <div className="ml-2 flex flex-col">
          <span className="line-clamp-1 text-left">{name}</span>
          {slug && (
            <span className="text-sm text-disabled  text-left line-clamp-1">
              {slug}
            </span>
          )}
        </div>
      </div>
      <div>
        {ActionsComponents != null && (
          <Popout zIndex={zIndex}>
            <PopoutTrigger>
              <div className="flex flex-row items-center">
                <Icon name="more" color="offBlack" />
              </div>
            </PopoutTrigger>
            <PopoutContent>{ActionsComponents}</PopoutContent>
          </Popout>
        )}
        {selectable && (
          <div className={selectableAccessoryClasses}>
            <Icon name={selected ? 'times' : 'check'} color="white" size={14} />
          </div>
        )}
      </div>
    </button>
  )
}
