import classnames from 'classnames'
import CLAvatar from '../common/CLAvatar'
import Icon from '../common/Icon'

export default function SmallUserItem({ user, onClick }) {
  const firstName = user?.name?.split(' ')[0]
  const wrapperStyle = classnames(
    'flex flex-col items-center',
    { 'cursor-pointer focus:outline-none': onClick },
    { 'pointer-events-none': !onClick }
  )

  return (
    <button className={wrapperStyle} onClick={onClick}>
      <div className="relative">
        <CLAvatar
          key={`${user?.name}-sui-avatar`}
          avatar={user?.avatar}
          size={32}
          name={user?.name}
        />
        {onClick && (
          <div className="absolute -top-1 -right-1 bg-alert w-3 h-3 rounded-full flex items-center justify-center">
            <Icon name="times" color="white" size={12} />
          </div>
        )}
      </div>
      <span className="text-xs text-disabled">{firstName}</span>
    </button>
  )
}
