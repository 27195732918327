import useUser from '../../../lib/useUser'
import SearchMenu from './SearchMenu'
import UserAvatarMenu from './UserAvatarMenu'
import UserNotificationsMenu from './UserNotificationsMenu'

export default function MobileTopNav({ title }) {
  const { user } = useUser()

  return (
    <div className="md:hidden flex flex-row justify-between items-center px-4 py-2 sticky top-0 z-99 bg-alt-bg w-screen">
      <h1 className="font-bold text-2xl">{title}</h1>
      <div className="md:w-1/3 flex flex-row justify-end items-center space-x-4">
        <div>
          <SearchMenu />
        </div>
        <div>{user && <UserNotificationsMenu isMobile />}</div>
        <div className="mt-1.5">{user && <UserAvatarMenu />}</div>
      </div>
    </div>
  )
}
