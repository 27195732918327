import UserSearch from '../../users/UserSearch'
import Button from '../../common/Button'

export default function SelectParticipants({
  onNext,
  participants,
  onSelectParticipant,
}) {
  return (
    <div>
      <UserSearch
        selectedUsers={participants}
        onSelectUser={onSelectParticipant}
        selectable
      />
      <div className="flex justify-end mt-6">
        <Button primary onClick={onNext}>
          Next
        </Button>
      </div>
    </div>
  )
}
