import usePaginated from './api/usePaginated'

export default function useUsers(filters) {
  const { items, ...attributes } = usePaginated(
    () => '/api/users',
    (response) => response?.users,
    filters
  )
  return { users: items, ...attributes }
}
