import React from 'react'

const BasicErrorMessage = ({ error }) => {
  if (error) {
    return <p className="text-alert">{error}</p>
  }

  return null
}

export default BasicErrorMessage
