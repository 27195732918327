import React from 'react'

const SelectAvatar = ({ avatar, handleChange }) => (
  <div className="flex flex-initial flex-col items-center mr-7">
    {avatar && (
      <img
        src={`${avatar}`}
        className="rounded-full object-cover w-24 h-24 bg-placeholder-blue"
        alt="avatar"
      />
    )}
    {!avatar && (
      <div className="rounded-full object-cover w-24 h-24 bg-placeholder-blue" />
    )}
    <label
      htmlFor="upload-button"
      className="my-2 text-disabled border-disabled bg-opacity-0 rounded-3xl border-2 py-2 px-8 text-tiny"
    >
      Upload photo
    </label>
    <input
      type="file"
      id="upload-button"
      className="hidden"
      onChange={handleChange}
      accept="image/*"
    />
  </div>
)

export default SelectAvatar
