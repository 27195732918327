import classnames from 'classnames'
import { useRouter } from 'next/router'
import { useState } from 'react'
import urlFor from '../../../lib/urlFor'
import CreateGroup from './CreateGroup'
import SelectParticipants from './SelectParticipants'

export const STEPS = {
  details: 1,
  addParticipants: 2,
}

export default function CreateGroupWizard({
  onCreateSuccess,
  onStepChange,
  isChat,
  setModalChangesWithInput,
  participants,
  setParticipants
}) {
  const [step, updateStepState] = useState(STEPS.details)
  const router = useRouter()

  const setStep = (newStep) => {
    updateStepState(newStep)
    onStepChange?.(newStep)
  }

  const handleCreateSuccess = (group) => {
    onCreateSuccess(group)
    if (isChat) {
      router.push(urlFor('chat', group.chat_slug))
    } else {
      router.push(urlFor('group', group))
    }
  }

  const handleRemoveParticipant = (user) => {
    const index = participants.findIndex((u) => u.id === user.id)
    if (index === -1) {
      return
    }

    const newParticipants = [...participants]
    newParticipants.splice(index, 1)
    setParticipants(newParticipants)
  }

  const handleAddParticipant = (user) => {
    setParticipants([...participants, user])
  }

  const handleSelectParticipant = (user) => {
    const index = participants.findIndex((u) => u.id === user.id)
    if (index > -1) {
      handleRemoveParticipant(user)
    } else {
      handleAddParticipant(user)
    }
  }

  const stepClasses = (targetStep) =>
    classnames({
      hidden: step !== targetStep,
    })

  return (
    <div className="md:w-132">
      <div className={stepClasses(STEPS.details)}>
        <CreateGroup
          onCreateSuccess={handleCreateSuccess}
          onAddParticipants={() => setStep(STEPS.addParticipants)}
          participants={participants}
          removeParticipant={handleRemoveParticipant}
          setModalChangesWithInput={setModalChangesWithInput}
        />
      </div>
      <div className={stepClasses(STEPS.addParticipants)}>
        <SelectParticipants
          participants={participants}
          onSelectParticipant={handleSelectParticipant}
          onNext={() => setStep(STEPS.details)}
        />
      </div>
    </div>
  )
}
