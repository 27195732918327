import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import Label from './Label'

function TextInput({
  name,
  label,
  value,
  onChangeText,
  required,
  hint,
  placeholder,
  multi,
  dataCy,
  type = 'text',
  className,
  autoFocus,
  isError,
  register,
  charLimit = 0,
  showCharCount = false,
}) {
  const [showPassword, setShowPassword] = useState(false)
  const isPassword = type === 'password'
  const inputStyle = `bg-alt-bg rounded-md w-full py-3 placeholder-subtitle border-0 focus:outline-none focus:ring-1 focus:ring-subtitle ${
    isError ? 'border-2 border-red-500' : ' border-none'
  } ${isPassword ? 'pl-4 pr-10' : 'px-4'}`
  const inputProps = {
    name,
    type: isPassword && showPassword ? 'text' : type,
    autoFocus,
    className: `${inputStyle}`,
    placeholder,
    defaultValue: value || '',
    onChange: onChangeText ? (e) => onChangeText(e.target.value) : null,
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={`flex flex-col mb-4 mt-2 ${className}`}>
      <Label required={required}>{label}</Label>
      <div className="relative">
        {!multi ? (
          <input
            {...inputProps}
            {...(register && register(name, { required }))}
            data-cy={dataCy}
          />
        ) : (
          <textarea
            {...inputProps}
            {...(register && register(name, { required }))}
            rows={6}
            data-cy={dataCy}
          />
        )}
        {type === 'password' && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeSlashIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" />
            )}
          </button>
        )}
      </div>
      {hint && (
        <span
          className={`ml-4 mt-2 ${
            isError ? 'text-red-500' : 'text-subtitle italic'
          }`}
        >
          {hint}
        </span>
      )}
      {(showCharCount || !!charLimit) && (
        <span className="ml-4 mt-2 text-subtitle italic">
          {charLimit
            ? `${value?.length || 0}/${charLimit}`
            : `${value?.length || 0}`}
        </span>
      )}
    </div>
  )
}

export default TextInput
