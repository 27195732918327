import useSWR from 'swr'
import { useAnalytics } from '../components/context/AnalyticsProvider'
import createFormData from './createFormData'
import fetchJson from './fetchJson'
import { canEdit, canJoin, canParticipate } from './policies/groupPolicy'
import useMutators from './useMutators'
import useUser from './useUser'

export default function useGroup(slug, opts = {}) {
  const { trackData } = useAnalytics()
  const { invite } = opts

  let url = null
  if (invite != null) {
    url = `/api/group_invite/${invite}`
  } else if (slug) {
    url = `/api/groups/${slug}.json`
  }

  const {
    error,
    data,
    mutate: mutateGroup,
    isValidating,
    isLoading,
  } = useSWR(url)
  const group = data?.group
  const { user } = useUser()

  const api = useMutators(mutateGroup, {
    updateGroup,
    joinGroup: joinGroup.bind(this, group, user, opts),
    deleteGroup,
    requestModeratorStatus,
  })

  return {
    group,
    mutateGroup,
    isValidating,
    error,
    isLoading,
    leaveGroup: removeUser.bind(this, group?.id, mutateGroup, user?.id),
    removeUser: removeUser.bind(this, group?.id, mutateGroup),
    changeRole: changeRole.bind(this, group?.id),
    createGroup: createGroup.bind(this, mutateGroup),
    duplicateGroup: duplicateGroup.bind(this, mutateGroup),
    ...api,
    joinGroup: async () => {
      const result = await api.joinGroup()
      trackData('group_join', {
        user,
        group,
        joined_at: new Date().toISOString(),
      })
      return result
    },
    get isManager() {
      return group?.role === 'manager'
    },
    get isMember() {
      return group?.role === 'member'
    },
    get canJoin() {
      return canJoin(group?.role)
    },
    get canParticipate() {
      return canParticipate(group?.role)
    },
    get canEdit() {
      return canEdit(group?.role)
    },
    get inviteLink() {
      return `https://${window.location.host}/groups/${slug}?invite=${group?.token}`
    },
  }
}

export async function updateGroup(group) {
  const formData = createFormData({ group })

  return await fetchJson(`/api/groups/${group.slug}`, {
    method: 'PUT',
    body: formData,
  })
}

export async function createGroup(mutateGroup, group) {
  const formData = createFormData({ group })

  const resp = await fetchJson(`/api/groups`, {
    method: 'POST',
    body: formData,
  })

  mutateGroup((data) => ({ group: { ...data?.group } }))

  return resp
}

export async function duplicateGroup(mutateGroup, data) {
  const resp = await fetchJson(`/api/groups.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })

  mutateGroup((data) => ({ group: { ...data?.group } }))

  return resp
}

export async function removeUser(groupId, mutateGroup, userId) {
  await fetchJson(`/api/users/${userId}/groups/${groupId}`, {
    method: 'DELETE',
  })

  return mutateGroup(
    (data) => ({ group: { ...data?.group, role: 'not member' } }),
    false
  )
}

export async function joinGroup(group, user, opts) {
  const { invite } = opts
  const response = await fetchJson(`/api/users/${user?.slug}/groups.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ group_id: group?.id, invite }),
  })

  return response
}

export async function changeRole(groupId, userId, role) {
  return await fetchJson(`/api/users/${userId}/groups/${groupId}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ group: { role } }),
  })
}

export async function requestModeratorStatus(groupId, userId) {
  return await fetchJson(`/api/users/${userId}/groups/${groupId}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ group: { requesting_permission: true } }),
  })
}

export async function deleteGroup(slug) {
  return await fetchJson(`/api/groups/${slug}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  })
}
