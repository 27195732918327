/* eslint-disable no-restricted-globals */
import SingletonRouter, { Router } from 'next/router'
import { useEffect } from 'react'

const confirmationDialog = async (message) => window.confirm(message)

export const useLeavePageConfirmation = (
  shouldPreventLeaving,
  message = 'Are you sure you want to discard this message?'
) => {
  useEffect(() => {
    if (!SingletonRouter.router?.change) {
      return
    }

    const originalChangeFunction = SingletonRouter.router.change
    const originalOnBeforeUnloadFunction = window.onbeforeunload

    if (shouldPreventLeaving) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = originalOnBeforeUnloadFunction
    }

    if (shouldPreventLeaving) {
      SingletonRouter.router.change = async (...args) => {
        const [historyMethod, , as] = args
        const currentUrl = SingletonRouter.router?.state.asPath.split('?')[0]
        const changedUrl = as.split('?')[0]
        const hasNavigatedAwayFromPage = currentUrl !== changedUrl
        const wasBackOrForwardBrowserButtonClicked =
          historyMethod === 'replaceState'
        let confirmed = false

        if (hasNavigatedAwayFromPage) {
          confirmed = await confirmationDialog(message)
        }

        if (confirmed) {
          Router.prototype.change.apply(SingletonRouter.router, args)
        } else if (
          wasBackOrForwardBrowserButtonClicked &&
          hasNavigatedAwayFromPage
        ) {
          await SingletonRouter.router?.push(
            SingletonRouter.router?.state.asPath
          )

          const browserDirection = 'back'

          // eslint-disable-next-line no-unused-expressions
          browserDirection === 'back'
            ? history.go(1) // back button
            : history.go(-1) // forward button
        }
      }
    }

    return () => {
      SingletonRouter.router.change = originalChangeFunction
      window.onbeforeunload = originalOnBeforeUnloadFunction
    }
  }, [shouldPreventLeaving, message, confirmationDialog])
}
