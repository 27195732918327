import { useState } from 'react'
import CreateGroup from '../../forms/CreateGroup'
import BaseModal from './BaseModal'

export default function CreateGroupModal({ isOpen, onRequestClose, isChat }) {
  const [participants, setParticipants] = useState([])
  const [modalChangesWithInput, setModalChangesWithInput] = useState(false)

  const handleCloseEditModal = async () => {
    if ((modalChangesWithInput || participants?.length > 0) && isOpen) {
      const confirm = await window.confirm(
        'Are you sure you want to discard these changes?'
      )
      if (confirm) {
        onRequestClose()
        setParticipants([])
      }
    } else {
      onRequestClose()
      setParticipants([])
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      closeIcon
      title="Create group"
      onRequestClose={handleCloseEditModal}
    >
      <CreateGroup
        onCreateSuccess={onRequestClose}
        isChat={isChat}
        setModalChangesWithInput={setModalChangesWithInput}
        participants={participants}
        setParticipants={setParticipants}
      />
    </BaseModal>
  )
}
