import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import checkForFormChanges from '../../../lib/checkForFormChanges'
import handleSetImage from '../../../lib/handleSetImage'
import useGroup from '../../../lib/useGroup'
import { useLeavePageConfirmation } from '../../../lib/useLeavePageConfirmation'
import useUser from '../../../lib/useUser'
import BasicErrorMessage from '../../common/BasicErrorMessage'
import Icon from '../../common/Icon'
import Label from '../../common/Label'
import TextInput from '../../common/TextInput'
import { useAnalytics } from '../../context/AnalyticsProvider'
import SmallUserItem from '../../users/SmallUserItem'
import SelectAvatar from '../common/SelectAvatar'
import VisibilityButton from '../common/VisibilityButton'
import groupSchema from '../schemas/createGroup'

const CreateGroup = ({
  onCreateSuccess,
  participants,
  removeParticipant,
  onAddParticipants,
  setModalChangesWithInput,
}) => {
  const { trackData } = useAnalytics()
  const { createGroup } = useGroup()
  const [submitting, setSubmitting] = useState(false)
  const [avatar, setAvatar] = useState()
  const [fileError, setFileError] = useState(null)
  const [visibility, setVisibility] = useState('members')
  const [changesWithInput, setChangesWithInput] = useState(false)
  const { user } = useUser()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(groupSchema),
    defaultValues: {
      name: '',
      visiblity: 'members',
    },
  })


  const watchFields = useWatch({ control })
  useLeavePageConfirmation(
    (changesWithInput || participants?.length > 0),
    'Are you sure you want to discard these changes?'
  )

  useEffect(() => {
    const isFieldChanged = checkForFormChanges(watchFields, {
      name: '',
      visiblity: 'members',
    })
    setChangesWithInput(isFieldChanged)
    setModalChangesWithInput(isFieldChanged)
  }, [watchFields])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(null)
    }
  }, [isSubmitSuccessful, reset])

  const onSubmit = async (data, e) => {
    setChangesWithInput(false)
    setSubmitting(true)
    const { name } = data

    const body = {
      name,
      visibility,
      avatar: avatar?.raw,
      user_ids: participants?.map((u) => u.id),
    }

    try {
      const response = await createGroup(body)
      onCreateSuccess(response?.group)
      e.target.reset()
      setSubmitting(false)

      trackData('group_create', {
        user,
        group: response?.group,
      })
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setSubmitting(false)
      setChangesWithInput(true)
    }

    setChangesWithInput(false)
  }

  return (
    <div className="w-full px-2 md:px-0 flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col w-full"
      >
        <div className="flex flex-col items-center mt-4">
          <SelectAvatar
            avatar={avatar?.preview || avatar}
            handleChange={(e) =>
              handleSetImage(e, setAvatar, setFileError, {
                maxWidth: 384,
                quality: 0.5,
              })
            }
          />
          <BasicErrorMessage error={fileError} />

          <TextInput
            name="name"
            label="Group Name"
            required
            hint={errors?.name?.message}
            dataCy="input-group-modal-name"
            className="flex w-full flex-col mb-6"
            register={register}
          />

          <div className="flex flex-row">
            <VisibilityButton
              selected={visibility === 'members'}
              title="Private"
              icon="lock"
              label="Private groups are hidden and members must be invited"
              onClick={() => setVisibility('members')}
            />
            <VisibilityButton
              selected={visibility === 'everyone'}
              title="Public"
              icon="globe"
              label="Public groups can be seen and joined by anyone"
              onClick={() => setVisibility('everyone')}
            />
          </div>

          <div className="flex flex-col w-full my-6">
            <Label>Participants</Label>

            <div className="flex flex-wrap">
              <div key={user?.id} className="mr-4 mb-4">
                <SmallUserItem user={user} />
              </div>
              {participants.map((u) => (
                <div key={u.id} className="mr-4 mb-4">
                  <SmallUserItem
                    user={u}
                    onClick={() => removeParticipant(u)}
                  />
                </div>
              ))}

              <button
                onClick={(e) => {
                  e.preventDefault()
                  onAddParticipants()
                }}
                className="cursor-pointer text-disabled text-sm flex items-center focus:outline-none"
              >
                <Icon name="plus" color="disabled" size={18} className="mr-2" />
                Add participants
              </button>
            </div>
          </div>

          <input
            type="submit"
            value="Create"
            disabled={submitting}
            className="px-16 py-2 rounded-full text-white cursor-pointer bg-topic-green self-end my-2"
            data-cy="input-group-modal-submit"
          />
        </div>
      </form>
    </div>
  )
}

export default CreateGroup
