import Icon from '../../common/Icon'

const VisibilityButton = ({ title, label, icon, selected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`w-1/2 h-44 rounded border-topic-green items-center mx-1 ${
      selected ? 'bg-white border-topic-green border-2' : 'bg-gray-100'
    }`}
  >
    <div className="flex flex-col h-full items-center justify-center px-3">
      <div className="flex flex-row items-center">
        <Icon
          name={icon}
          size={28}
          color={selected ? 'topic-green' : 'black'}
          className="mr-1"
        />
        <div className="font-bold text-lg">{title}</div>
      </div>
      <div className="text-gray-600 text-center">{label}</div>
    </div>
  </button>
)

export default VisibilityButton
