export default function checkForFormChanges(watchFields, defaultValues) {
  return Object.keys(watchFields).some((key) => {
    const defaultValue = defaultValues[key];
    const watchValue = watchFields[key];

    if (typeof defaultValue === 'object' && typeof watchValue === 'object') {
      return JSON.stringify(defaultValue) !== JSON.stringify(watchValue);
    }

    return defaultValue !== watchValue;
  });
}
