export function canEdit(roleName) {
  return roleName && Role(roleName).isManager
}

export function canParticipate(roleName) {
  return roleName && !Role(roleName).isNotMember
}

export function canJoin(roleName) {
  return roleName && Role(roleName).isNotMember
}

function Role(roleName) {
  const formattedRole = roleName?.toLowerCase()
  return {
    isMember: formattedRole === 'member',
    isNotMember: formattedRole === 'not member',
    isManager: formattedRole === 'manager',
  }
}
