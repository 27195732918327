import compressImage from './compressImage'

export default function handleSetImage(
  e,
  setImage,
  setFileError,
  compressOptions
) {
  if (e.target.files.length) {
    const file = e.target.files[0]
    compressImage(setImage, file, compressOptions, setFileError)
  } else {
    setImage(null)
  }
}
